.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: #c1dce8;
  border-radius: 30px;
  padding: 10px;
}

.search_input {
  color: #185446;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
}

.searchbar > .search_input {
  padding: 0 10px;
  width: 450px;
  caret-color: #fff;
  transition: width 0.4s linear;
}

.searchbar:hover > .search_icon {
  background: white;
  color: #e74c3c;
}

.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
}

/*/////////////////*/
@media print {
  body * {
    visibility: hidden;
  }
  #dashboard_adv_search_pdf,
  #dashboard_adv_search_pdf * {
    visibility: visible;
  }
  #dashboard_adv_search_pdf {
    position: absolute;
    left: 0;
    top: 0;
  }
}

#dashboard_adv_search_pdf {
  background: #aaaaaa;
  width: 100%;
  font-size: 12px;
}

/*/////////////////*/
