
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
.dashboard-modal-main {
  position: fixed;
  background: white;
  width: 70%;
  max-height:500px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
.dashboard-modal-main {
  position: fixed;
  background: white;
  width: 70%;
  max-height:500px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
.dashboard-modal-main {
  position: fixed;
  background: white;
  width: 70%;
  max-height:500px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
.dashboard-modal-main {
  position: fixed;
  background: white;
  width: 70%;
  max-height: 850px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  
  
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
.dashboard-modal-main {
  position: fixed;
  background: white;
  width: 70%;
 max-height:700px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  
}


@media only screen and (min-width: 2000px) {
 
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
.dashboard-modal-main {
  position: fixed;
  background: white;
  width: 70%;
  max-height: 860px;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  
}